import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostItem from "../components/post-item"
import Pagination from "../components/pagination"
import SEO from "../components/seo"

export default ({
  data,
  pageContext: { year, month, pageNumber, numberOfPages },
  location,
}) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location}>
      <SEO
        title={
          pageNumber === 0
            ? `${year}/${month}`
            : `${year}/${month} page ${pageNumber + 1}`
        }
        pathname={location.pathname}
      />
      <h1>{`${year}/${month}`}</h1>
      {posts.map(({ node }) => {
        const {
          html,
          id,
          frontmatter: { title, tags, date, datetime },
          fields: { slug },
        } = node
        return (
          <PostItem
            key={id}
            title={title}
            date={date}
            dateTime={datetime}
            html={html}
            tags={tags}
            slug={slug}
            excerpt
          />
        )
      })}
      <Pagination
        pageNumber={pageNumber}
        numberOfPages={numberOfPages}
        basePath={`/${year}/${month}`}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($startDate: Date!, $endDate: Date!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { date: { gte: $startDate, lt: $endDate } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "YYYY年MM月DD日")
            datetime: date(formatString: "YYYY-MM-DD")
            tags
            title
          }
          fields {
            slug
          }
          html
          id
        }
      }
    }
  }
`
