import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const PaginationItem = ({ number, basePath, current }) => {
  if (current) {
    return (
      <div
        css={css`
          width: 40px;
          height: 40px;
          color: #fff;
          font-size: 12px;
          background-color: #555;
          border-radius: 50%;
          text-align: center;
          margin: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <span>{number + 1}</span>
      </div>
    )
  }

  if (number === 0) {
    return (
      <Link
        to={`${basePath}`}
        css={css`
          width: 40px;
          height: 40px;
          color: #fff;
          &:visited {
            color: #fff;
          }
          &:hover {
            color: #fff;
          }
          font-size: 12px;
          text-decoration: none;
          background-color: #000;
          border-radius: 50%;
          text-align: center;
          margin: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {number + 1}
      </Link>
    )
  } else {
    if (basePath === `/`) {
      return (
        <Link
          to={`/page/${number + 1}`}
          css={css`
            width: 40px;
            height: 40px;
            color: #fff;
            &:visited {
              color: #fff;
            }
            &:hover {
              color: #fff;
            }
            font-size: 12px;
            text-decoration: none;
            background-color: #000;
            border-radius: 50%;
            text-align: center;
            margin: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          {number + 1}
        </Link>
      )
    }

    return (
      <Link
        to={`${basePath}/${number + 1}`}
        css={css`
          width: 40px;
          height: 40px;
          color: #fff;
          &:visited {
            color: #fff;
          }
          &:hover {
            color: #fff;
          }
          font-size: 12px;
          text-decoration: none;
          background-color: #000;
          border-radius: 50%;
          text-align: center;
          margin: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {number + 1}
      </Link>
    )
  }
}

export default PaginationItem
