import React from "react"
import { css } from "@emotion/core"
import PaginationItem from "./pagination-item"

const Pagination = ({ pageNumber, numberOfPages, basePath }) => {
  let pre
  if (pageNumber === 0) {
    pre = <></>
  } else if (pageNumber === 1) {
    pre = (
      <>
        <PaginationItem number={0} basePath={basePath} />
      </>
    )
  } else if (pageNumber === 2) {
    pre = (
      <>
        <PaginationItem number={0} basePath={basePath} />
        <PaginationItem number={1} basePath={basePath} />
      </>
    )
  } else {
    pre = (
      <>
        <PaginationItem number={0} basePath={basePath} />
        ...
        <PaginationItem number={pageNumber - 1} basePath={basePath} />
      </>
    )
  }

  let post
  if (pageNumber === numberOfPages - 1) {
    post = <></>
  } else if (pageNumber + 1 === numberOfPages - 1) {
    post = (
      <>
        <PaginationItem number={numberOfPages - 1} basePath={basePath} />
      </>
    )
  } else if (pageNumber + 2 === numberOfPages - 1) {
    post = (
      <>
        <PaginationItem number={numberOfPages - 2} basePath={basePath} />
        <PaginationItem number={numberOfPages - 1} basePath={basePath} />
      </>
    )
  } else {
    post = (
      <>
        <PaginationItem number={pageNumber + 1} basePath={basePath} />
        ...
        <PaginationItem number={numberOfPages - 1} basePath={basePath} />
      </>
    )
  }

  return (
    <div
      css={css`
        box-sizing: border-box;
        margin-bottom: 50px;
        padding: 0 30px;
        display: flex;
        justify-content: center;
      `}
    >
      {pre}
      <PaginationItem number={pageNumber} current />
      {post}
    </div>
  )
}

export default Pagination
